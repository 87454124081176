// import runtimeEnv from '@mars/heroku-js-runtime-env';

// const {
//   REACT_APP_BACKEND_URL,
//   REACT_APP_ENVIRONMENT,
//   REACT_APP_CONTINGENCY_ON,
//   REACT_APP_ORG_ID,
//   REACT_APP_STRIPE_KEY,
//   REACT_APP_PEKELACH_ORDERING_DONE,
// } = runtimeEnv();

require('dotenv').config()
const REACT_APP_BACKEND_URL= `${process.env.REACT_APP_BACKEND_URL}`;
const REACT_APP_ENVIRONMENT= `${process.env.REACT_APP_ENVIRONMENT}`;
const REACT_APP_CONTINGENCY_ON= `${process.env.REACT_APP_CONTINGENCY_ON}`;
const REACT_APP_ORG_ID= `${process.env.REACT_APP_ORG_ID}`;
const REACT_APP_STRIPE_KEY= `${process.env.REACT_APP_STRIPE_KEY}`;
const REACT_APP_PEKELACH_ORDERING_DONE= `${process.env.REACT_APP_PEKELACH_ORDERING_DONE}`;

export const CONTINGENCY_ON = (REACT_APP_CONTINGENCY_ON || 'false') === 'true';
export const ORG_ID = REACT_APP_ORG_ID || 2;
export const PEKELACH_ORDERING_DONE =
  (REACT_APP_PEKELACH_ORDERING_DONE || 'false') === 'true';
export const STRIPE_KEY =
  REACT_APP_STRIPE_KEY ||
  'pk_test_51I6gSuDg4amxsE5qH6NREoMywYfr1DBP1Bo2cuIr6ZRye7AhKXu7XuQ8rzBsc320OVZ92umwSo1pzSzR5chiTnMs00eNu691QJ';

export const BACKEND_URL =
  REACT_APP_BACKEND_URL || 'https://ezpurim-api.herokuapp.com/';

export const ENVIRONMENT = REACT_APP_ENVIRONMENT || 'local';

export const JWT_KEY = `ezp.jwt.${ENVIRONMENT}`;
export const USER_FULLNAME_KEY = `ezp.fullname.${ENVIRONMENT}`;
export const USER_LASTNAME_KEY = `ezp.lastname.${ENVIRONMENT}`;
export const USER_PHONE_NUMBER = `ezp.phoneNumber.${ENVIRONMENT}`;
export const PERSONAL_GREETINGS = `ezp.greetings.${ENVIRONMENT}`;
export const CUSTOMER_ID = `ezp.customer.${ENVIRONMENT}`;

export const DATE_END_PEKELACH = 15;
